/* global site */

// Import site modules
// import ('./site/cookie-policy.js');
import ('./site/_Menu.js');
import ('./site/_MobileMenu.js');
import ('./site/HomeSlider.js');
import ('./site/ArtistsSlider.js');
import ('./site/ArtistVideos.js');
import ('./site/ScrollTo.js');

window.site = (window.site || {});

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = (function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  let inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */
  const config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {},
  };

  /**
   * Initializes the class.
   * @public
   */
  const init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Store application settings
    options = (options || {});

    if (options.env) { config.env = options.env; }
    if (options.csrf) { config.csrf = options.csrf; }
    if (options.csrfName) { config.csrfName = options.csrfName; }
    if (options.locale) { config.locale = options.locale; }
    if (options.device) { config.device = options.device; }
    if (options.preview) { config.preview = options.preview; }
    if (options.general) { config.general = options.general; }

    // Global
    // Detect css-grid un-supported
    const el = document.createElement("div")
    const supportsGrid = "string" == typeof el.style.grid;

    if(!supportsGrid) {
      const body = document.getElementsByTagName("body")[0].classList.add('is-ie-11');
    } else {
      // IE 11 || Old Browser - Ignore zone
      console.table(options);

      // Register ServiceWorker
      // if ('serviceWorker' in navigator) {
      //   console.log('Registering ServiceWorker...');
      //   window.addEventListener('load', function() {
      //       navigator.serviceWorker.register('/sw.js', {
      //           scope: "/"
      //       }).then(function(registration) {
      //           // Registration was successful
      //           console.log('ServiceWorker registration successful with scope: ', registration.scope);
      //           // Trim the caches on load
      //           navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage({
      //               command: "trimCaches"
      //           });
      //      }).catch(function(err) {
      //           // registration failed :(
      //           console.log('ServiceWorker registration failed: ', err);
      //       });
      //   });
      // }

      // PreRender for fast website
      function prerenderlink(e) {
        console.log(e, e.currentTarget, e.currentTarget.href);
        var head = document.getElementsByTagName("head")[0];
        var refs = head.childNodes;
        var ref = refs[ refs.length - 1];

        var elements = head.getElementsByTagName("link");
        Array.prototype.forEach.call(elements, function(el, i) {
          if (("rel" in el) && (el.rel === "prerender"))
            el.parentNode.removeChild(el);
        });

        var prerenderTag = document.createElement("link");
        prerenderTag.rel = "prerender";
        prerenderTag.href = e.currentTarget.href;
        ref.parentNode.insertBefore(prerenderTag,  ref);
      }

      const $prerenderLinks = document.querySelectorAll('#page-header a');
      [].slice.call($prerenderLinks).forEach(function(elem) {
        elem.addEventListener('mouseenter', prerenderlink);
      });

      [].slice.call(document.querySelectorAll('.ticketing-filter')).forEach(function(elem) {
        elem.addEventListener('change', function(e) {
          e.preventDefault();

          var secteurSlug = document.querySelector('#select-artiste').value ? document.querySelector('#select-artiste').value : 'all';
          var typeSlug = document.querySelector('#select-ville').value ? document.querySelector('#select-ville').value : 'all';
          var horaireSlug = document.querySelector('#select-mois').value ? document.querySelector('#select-mois').value : 'all';

          window.location.href = '/billetterie/' + secteurSlug + '/' + typeSlug + '/' + horaireSlug

        });
      });

      const personnagesRow = [].slice.call(document.querySelectorAll('.personnages-item .top a'));

      personnagesRow.forEach(function(elem) {
        elem.addEventListener('click', function(e) {
          e.preventDefault();

          const personnagesItem = e.currentTarget.closest('.personnages-item');

          personnagesItem.classList.toggle('is-active');
        });
      });
    }

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  const getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init,
    config: getConfig,
  };
}());
